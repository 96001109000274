import React from 'react'

import Card from './Card'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

// import pic06 from '../assets/images/pic06.jpg'
import { Link } from 'gatsby'

const options = {
  // overlayColor: 'rgb(200, 200, 200)',
  showCaption: false,
  enablePanzoom: false,
  // buttonsBackgroundColor: 'rgba(140, 94, 88, 0.8)',
  buttonsIconColor: 'rgba(219, 219, 219, 0.7)',
  showThumbnails: false,
  transitionSpeed: 500,
  transitionTimingFunction: 'linear'
}

export default function Intro () {
  return (
    <Card id="main" bottomImage={true}>
      <header>
        <h2>
          Videoispezione e rilevamento
          <br />
          fotogrammetrico con droni
        </h2>
      </header>
      <p>
        Operazioni di videoispezione e di rilevamento fotogrammetrico in
        situazioni di rischio per gli operatori
        <br />
        Realizzazione di elaborati cartografici in grande scala, ortofotografie
        e DTM
        <br />
        Riprese di eventi sportivi, riprese giornalistiche, paesaggistiche e
        naturalistiche, documentari{' '}
      </p>

      <Link to="/lavori-con-drone" className="button alt">
        Scopri di più
      </Link>

      <h3
        style={{
          borderTop: 'solid 2px #e5e5e5',
          paddingTop: '1em',
          margin: '2em auto 1em'
        }}
      >
        Galleria fotografica
      </h3>
      {/* class name 'SRLLightbox' here is just for preventing purgeCss to remove a specific rule */}
      <SimpleReactLightbox className={'SRLLightbox'}>
        <SRLWrapper options={options}>
          <div className="row gtr-uniform">
            {[...Array(16).keys()].map(i => (
              <div className="col-3 col-6-narrower" key={`img_${i}`}>
                <span className="image fit">
                  <a
                    href={`/images/gallery/${i < 10 ? '0' + i : i}.jpg`}
                    data-attribute="SRL"
                  >
                    <img
                      style={{ maxHeight: '128px' }}
                      src={`/images/gallery/${i < 10 ? '0' + i : i}_tn.jpg`}
                      alt={`Foto ${i}`}
                    />
                  </a>
                </span>
              </div>
            ))}
          </div>
        </SRLWrapper>
      </SimpleReactLightbox>
    </Card>
  )
}
