import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Helmet from 'react-helmet'
import '../assets/sass/main.scss'
import siteSections from '../menuItems'
import Banner from './Banner'
import Cta from './Cta'
import Footer from './Footer'
import Header from './Header'
import MobileMenu from './MobileMenu'

class Layout extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      isPreloaded: true,
      stickyNav: undefined // explicitely set to undefined, see below
    }
    this.onDrawerClose = this.onDrawerClose.bind(this)
    this.toggleDrawer = this.toggleDrawer.bind(this)
    this.listenToScroll = this.listenToScroll.bind(this)
  }

  componentDidMount () {
    window.addEventListener('scroll', this.listenToScroll)
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false })
    }, 10000)
  }

  listenToScroll () {
    const offset = window.pageYOffset
    if (offset > 110) {
      this.setState(() => ({ stickyNav: 'reveal' }))
    } else {
      this.setState(() => ({ stickyNav: 'alt reveal' }))
    }
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.listenToScroll)

    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  onDrawerClose () {
    this.setState({ open: false })
  }

  toggleDrawer () {
    this.setState({ open: !this.state.open })
  }

  render () {
    const { children } = this.props
    const { isPreloaded, stickyNav } = this.state

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'AD Topografia - Studio geometra Lecco - Lavori topografici con drone' },
                { name: 'keywords', content: 'ad topografia, AD Topografia, servizi topografici, lavori con drone, geometra lecco, studio geometra lecco, geometra' }
              ]}
            >
              <html lang="en" />
              <body className={`landing ${this.state.open && 'navPanel-visible'}`} />
            </Helmet>
            <div
              id="page-wrapper"
              className={
                isPreloaded ? 'page-wrapper is-preload' : 'page-wrapper'
              }
            >
              <Header className={`${stickyNav || 'alt reveal'}`} menuItems={siteSections} useGatsbyLink={false} />
              {/*
               * Using ${stickyNav || 'alt reveal'} so that purgeCss doesn't purge 'reveal'
               * 'alt' is for making the header transparent
              * 'reveal' is for having a smooth transition */}
              <Banner />
              {children}
              <Cta />
              <Footer />
            </div>
            <MobileMenu
              showBackdrop={this.state.open}
              menuItems={siteSections}
              toggleDrawer={this.toggleDrawer}
              useGatsbyLink={false}
            />
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
