export default [
  {
    id: 'banner',
    label: 'Home'
  },
  {
    id: 'team',
    label: 'Chi siamo'
  },
  {
    id: 'services',
    label: 'Servizi'
  },
  {
    id: 'equipment',
    label: 'Strumentazione'
  },
  {
    id: 'contacts',
    label: 'Contatti'
  }
]
