import React from 'react'
import Contacts from '../components/Contacts'
import Container from '../components/Container'
import Equipment from '../components/Equipment'
import Intro from '../components/Intro'
import Layout from '../components/Layout'
import Services from '../components/Services'
import Team from '../components/Team'

const IndexPage = () => (
  <Layout>
    <Container>
      <Intro />
      <Team />
      <Services />
      <Equipment />
      <Contacts />
    </Container>
  </Layout>
)

export default IndexPage
