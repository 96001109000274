import React from 'react'

import Card from './Card'
import pic01 from '../assets/images/pic01.jpg'

import { Link } from 'gatsby'

export default function Services () {
  return (
    <Card id="services" img={pic01}>
      <h3>Lavori topografici e catastali</h3>
      <p>
        AD Topografia opera nel settore della rilevazione offrendo un'ampia
        gamma di servizi, tra cui{' '}
        <strong>
          piani quotati, tracciamenti, monitoraggio cave o frane,
          riconfinazioni, livellazioni, calcolo dei volumi di scavo, tracciati
          di strade, profili altimetrici.
        </strong>
      </p>
      <p>
          Offriamo inoltre una serie di servizi catastali, tra cui{' '}
        <strong>
  frazionamenti, tipi mappale, accatastamento e variazione catastale, visure catastali,
  rettifica dati catastali, volture catastali
        </strong>
      </p>
      <Link to="/lavori-topografici-e-catastali" className="button alt">
        Scopri di più
      </Link>
    </Card>

  )
}
