import React from 'react'

export default function Banner () {
  return (
    /* <!-- Banner --> */
    <section id='banner'>
      <h2>AD Topografia</h2>
      <p>Studio tecnico topografico</p>
    </section>
  )
}
