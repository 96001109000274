import React from 'react'
import PropTypes from 'prop-types'
// Import css files
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

import drone from '../assets/images/drone.jpg'
import gnss from '../assets/images/gnss_hipersr_cutout_1.png'
import os101 from '../assets/images/os-101-3-800x640.jpg'
import Card from './Card'

const SliderImage = ({ img, caption }) =>
  <div>
    <img style={{ height: '250px' }} src={img} alt={caption} />
    <small>
      {caption}
    </small>
  </div>

SliderImage.propTypes = {
  img: PropTypes.string,
  caption: PropTypes.string
}

export default function Equipment () {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  }
  return (
    <>
      <Card id="equipment">
        <div className='row'>
          <div className='col-6 col-12-narrower'>
            <h3>Strumentazione</h3>
            <p style={{ textAlign: 'left' }}>
              Lo studio si avvale degli strumenti più
              avanzati nel campo della topografia:
            </p>
            <ul style={{ textAlign: 'left' }}>
              <li>Stazione Totale Topcon OS-101 precisione angolare 1"</li>
              <li>Ricevitore Satellitare Topcon HiPer SR</li>
              <li>Drone DJI PHANTOM 4 PRO fotocamera 20 mp risoluzione video 4K</li>
            </ul>
          </div>
          <Slider className='col-6 col-12-narrower' {...settings}>
            <SliderImage img={drone} caption={'Drone DJI PHANTOM 4 PRO'}/>
            <SliderImage img={gnss} caption={'Ricevitore Satellitare Topcon HiPer SR'}/>
            <SliderImage img={os101} caption={'Stazione Totale Topcon OS-101'}/>
          </Slider>
        </div>
      </Card>

    </>
  )
}
