import React from 'react'
import Obfuscate from 'react-obfuscate'
import Card from './Card'
import Helmet from 'react-helmet'

export default function Contacts () {
  return (
    <Card id="contacts">
      <Helmet>
        <style>{`
          #contacts p {
            margin: 0;
          }
          #contacts h4 {
            padding-top: 2em;
          }
          `}
        </style>
      </Helmet>
      <h3>Contatti</h3>
      <h4>AD Topografia</h4>
      <p>Via Palestro 2 - 23900 Lecco</p>
      <h4>Geom. Adamoli Daniele</h4>
      <p>cell: 328.1319875</p>
      <p>mail: <Obfuscate email={process.env.MAIL1} /></p>
      <p>P.IVA: 03052620139</p>
      <h4>Geom. Gottifredi Andrea</h4>
      <p>cell: 342.0044187</p>
      <p>mail: <Obfuscate email={process.env.MAIL2} /></p>
    </Card>
  )
}
