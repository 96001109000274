import React from 'react'

import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import Card from './Card'

export default function Team () {
  return (
    <>
      <Card id='team'>
        <header className='major'>
          <h2>Chi siamo</h2>
        </header>
        <p>
          Nato nel 2007, lo Studio Tecnico si proponeva di offrire una risposta
          unica nel settore topografico e catastale.
          <br />
          Ora, grazie all’accumularsi di esperienza, all’aumento delle proprie
          collaborazioni e al continuo aggiornamento tecnologico di tutta la
          strumentazione, la società è in grado di fornire un servizio che si
          distingue per la qualità e la precisione nel campo topografico,
          catastale.
          <br />
          Negli ultimi anni lo Studio ha acquisito particolare competenza, oltre
          che nell’ambito topografico tradizionale mediante l’utilizzo di
          Stazione Totale e GPS, anche nell’ambito dei rilievi eseguiti mediante
          l’utilizzo di Drone . Lo Studio Tecnico è una realtà giovane e
          dinamica attenta sia alle esigenze del privato che alle necessità di
          professionisti, Imprese ed Enti Pubblici.
        </p>
      </Card>
      <div className='row'>
        <Card className='col-6 col-12-narrower' img={pic02}>
          <h3>Geom. Adamoli Daniele</h3>
          <p>Iscritto al Collegio dei Geometri di Lecco al n. 794</p>
        </Card>
        <Card
          className='col-6 col-12-narrower'
          img={pic03}>
          <h3>Geom. Gottifredi Andrea</h3>
          <p>Pilota APR abilitato per volare nelle aree critiche</p>
        </Card>
      </div>

    </>
  )
}
